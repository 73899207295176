import React from "react";
import TablesData from "./table";

function Home() {
    return (
        <div>
            <TablesData />
        </div>
    );
}
export default Home;