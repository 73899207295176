import React from "react";
import ReportData from "./reportData";

function Report() {
    return (
        <div>
            <ReportData />
        </div>
    );
}
export default Report;