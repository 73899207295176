import React from "react";
import SuccessData from "./success";

function Success() {
    return (
        <div>
            <SuccessData />
        </div>
    );
}
export default Success;